<template>
  <div class="marktit">
      <div class="mleft">
         <v-md-preview :text="text" ref="preview" ></v-md-preview>
      </div>
      <el-affix target=".marktit" position="top" :offset="120">
        <ul>
            <li
                v-for="(anchor ,index ) in titles"
                :key="index"
                :class="{'act': midx == index } "
                
                @click="handleAnchorClick(anchor,index)"
                >
                <a class="text1 point" :style="{ marginLeft: ` ${anchor.indent * 20}px` }" >{{ anchor.title }}</a>
            </li>
        </ul>
      </el-affix>
  </div>
</template>
<script>

export default {
  data() {
    return {
      titles: [],
      midx: 0,
      t1: 0,
      t2: 0,
      timer : null,
      scrollTop: 0,
    };
  },
  props:{
      text:{
          type: String,
          default: ''
      }
  },
  watch:{
      text :{
          handler(val,old){
              // console.log(val,old)
              if(val != old){
                this.$nextTick(()=>{
                    this.msatrt()
                })
              }
          },
          deep: true
      },
      scrollTop(newValue, oldValue) {

        setTimeout(() => {

            if(newValue == window.scrollY) { //延时执行后当newValue等于window.scrollY，代表滚动结束
                // console.log('滚动结束');
                this.oldScrollTop = newValue; //每次滚动结束后都要给oldScrollTop赋值
            };

        }, 20); //必须使用延时器，否则每次newValue和window.scrollY都相等，无法判断，20ms刚好大于watch的侦听周期，故延时20ms

        if(this.oldScrollTop == oldValue) { //每次滚动开始时oldScrollTop与oldValue相等

            // console.log('滚动开始');

        }
      }
  },
  mounted() {
      var that = this;
    window.onscroll = function() {
        
        //为了保证兼容性，这里取两个值，哪个有值取哪一个
        // scrollTop就是触发滚轮事件时滚轮的高度
        var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        // console.log("滚动距离" + scrollTop);
        that.$nextTick(()=>{
            that.scrollTop = scrollTop;
        })
        // console.log(that.scrollTop,scrollTop)
    }
      
  },
  methods: {
    msatrt(){
        const anchors = this.$refs.preview.$el.querySelectorAll('h1,h2,h3,h4,h5,h6');
        // console.log(anchors)
        const titles = Array.from(anchors).filter((title) => !!title.innerText.trim());

        if (!titles.length) {
            this.titles = [];
            return;
        }

        const hTags = Array.from(new Set(titles.map((title) => title.tagName))).sort();

        this.titles = titles.map((el) => ({
            title: el.innerText,
            lineIndex: el.getAttribute('data-v-md-line'),
            indent: hTags.indexOf(el.tagName),
            top: el.offsetTop -120
        }));
        // console.log(this.titles)
    },
    handleAnchorClick(anchor,idx) {
      this.midx = idx;
        // console.log(anchor)
        const { preview } = this.$refs;
      const { lineIndex } = anchor;

      const heading = preview.$el.querySelector(`[data-v-md-line="${lineIndex}"]`);
        // console.log(heading)

      if (heading) {
        preview.scrollToTarget({
          target: heading,
          scrollContainer: window,
          top: 120,
        });
      }
    },

  },
};
</script>
<style lang="scss" scoped>
.marktit{
    display: flex;
    align-items: flex-start;
    ul{
        max-width: 300px;
        min-width: 200px;
        // padding: 0px 0 0px 35px;
        font-size: 12px;
        li{
            width: fit-content;
            padding: 10px 0 10px 35px;
            border-left: 2px solid #E5E5E5;
            a{
              color: #737373;
            }
        }
        >.act{
            border-color: #FF6A00;
            a{ color: #FF6A00;}
        }
    }
    // .colorg
}
.mleft{
    width: calc( 100% - 250px ) ;
    padding-right: 30px;
}
</style>