<template>
    <p class="h20"></p>
    <div class="bgf padd30" v-loading = "loading">
        <p class="col9 font14 www">
            <span class="inbl padr40">{{info.categoryName}}   </span>
            文档类型：{{['','知识文档','Bug文档','COE文档'][info.docType]}}
        </p>
        <h1 class="padt14 font30 padb10 www">{{info.docTitle}}（文档编号 {{info.docNumber}}）</h1>

        <div class="col9 font14 lh20 flexab padb30 www" >
            <div>
                创建于 {{info.createTime}} &nbsp;&nbsp;&nbsp;&nbsp;
                最近更新时间：{{info.updateTime}}
            </div>

            <div class="flex flexa">
                <span class="padr40">引用次数 {{info.linkCount}} </span>
                
                <div @click="toColect" class="point flexa flex">
                    <img v-if="info.isCollect==1" :src="utils.loadImg('xing-a.png')" alt=""  /> 
                    <img v-else :src="utils.loadImg('xing.png')" alt=""  /> 
                    &nbsp;&nbsp;
                    · 我的收藏
                </div>
            </div>
        </div >
       
        <marktitle :text="info.docContent" ></marktitle>
        <!-- <v-md-preview :text="text" ref="preview" ></v-md-preview> -->
    </div>

    <el-dialog title="添加到收藏夹" width="530px" v-model="colect">
        <div >
            <p class="col9a font12 padb10">请选择你想要添加的收藏夹</p>
            <ul class="ul1">
                <li v-for="(item,index) in colectL" :key="index" >
                    <span>{{item.favoritesName}}（{{item.count}}）</span>
                    <el-button @click="colecSub(item.id ,1)" size="small" plain type="primary" class="w68" >收藏</el-button>
                </li>
            </ul>
        </div>
    </el-dialog>
</template>
<script>

import { detailPageInfo, accountCollect, accountBaseInfo } from "@/utils/api1.js"
import marktitle from "@/components/marktitle.vue"
export default {
    components:{ marktitle},
    data(){
        return{
            titles: [],
            text:'',
            loading: false,
            id: this.$route.params.id,
            info:{},
            colect: false,
            colectL:[],
        }
    },
    mounted(){
        this.getData();
    },
    methods:{
        toColect(){
            if(this.info.isCollect==0){
                this.loading = true;
                accountBaseInfo().then(res=>{
                    console.log(res);
                    if(res){
                        this.colectL = res.favoritesVoList;
                    };
                    this.colect = true;
                    this.loading = false;
                });
            }else{
                this.colecSub('',0)
            }
        },

        colecSub(id, num){
            
            accountCollect({docId:this.id,favoritesId:id}).then(res=>{
                console.log(res);
                if(res){
                    this.$message.success(res.message);
                    this.info.isCollect = num;
                };
                this.colect = false;
            });

        },
        getData(){
            // console.log()
            detailPageInfo(this.id).then(res=>{
                console.log(res);
                if(res){
                    this.info = res;
                    this.text = res.docContent;
                }
            });
        },
    }
}
</script>
<style lang="scss" scoped>
.ul1{
    li{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px 0;
    }
}
.www {
    width: calc( 100% - 250px ) ;
}
</style>